import { Box, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReportTable from './ReportTable';
import {
  calculateSpendTabColumns,
  CATEGORY_SPENDING_STATS_HEADERS,
  EMPLOYEE_SPENDING_STATS_HEADERS,
  generateQueryParams,
  SUPPLIER_SPENDING_STATS_HEADERS,
} from './reportHelpers';
import { encodeQuery } from '../../store/storeFunctions';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import SpendGraphs from './SpendGraphs';
import { FetchWithCancel } from '../../common/FetchWithCancel';

const TableLoadingDiv = (title) => {
  return (
    <Box
      sx={{
        border: '1.5px solid #694FA31A',
        borderRadius: '12px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          borderBottom: '1.5px solid #694FA31A',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
          paddingBottom={'4px'}
        >
          {title}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.medium}
          ></Box>
        </Box>
      </Box>
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    </Box>
  );
};

const SpendingStats = ({ filters }) => {
  const [employeeCollection, setEmployeeCollection] = useState([]);
  const [categoryCollection, setCategoryCollection] = useState([]);
  const [supplierCollection, setSupplierCollection] = useState([]);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(true);
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const [isSupplierLoading, setIsSupplierLoading] = useState(true);

  const employeeTableOptions = {
    type: 'employee',
    includeBorder: true,
    includeHeader: true,
    plural: 'employees',
    title: 'Spend by employees',
    modal: true,
    requestModal: true,
    supplierModal: true,
    pivot: 'user_email',
    key: 'user_email',
  };

  const categoryTableOptions = {
    type: 'category',
    includeBorder: true,
    includeHeader: true,
    plural: 'categories',
    title: 'Spend by categories',
    modal: true,
    requestModal: true,
    supplierModal: true,
    pivot: 'category',
    key: 'category',
  };

  const supplierTableOptions = {
    type: 'supplier',
    includeBorder: true,
    includeHeader: true,
    plural: 'suppliers',
    title: 'Spend by suppliers',
    modal: true,
    requestModal: true,
    pivot: 'supplier_id',
    key: 'supplier_id',
  };

  const getStats = (spendType) => {
    const query = {
      ...generateQueryParams(filters, 'MM/DD/YYYY'),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/${spendType}`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    setIsEmployeeLoading(true);
    setIsCategoryLoading(true);
    setIsSupplierLoading(true);

    const { fetchPromise: spendTeamFetch, cancel: spendTeamCancel } =
      getStats('spend_team');

    const { fetchPromise: spendCategoryFetch, cancel: spendCategoryCancel } =
      getStats('spend_category');
    const { fetchPromise: spendSupplierFetch, cancel: spendSupplierCancel } =
      getStats('spend_supplier');

    spendTeamFetch.then((reportData) => {
      if (reportData !== 'error') {
        const employeeData = calculateSpendTabColumns(
          reportData?.spend_pivot_team || [],
        );
        setEmployeeCollection(employeeData);
      } else {
        setEmployeeCollection([]);
      }
      setIsEmployeeLoading(false);
    });

    spendCategoryFetch.then((reportData) => {
      if (reportData !== 'error') {
        const categoryData = calculateSpendTabColumns(
          reportData?.spend_pivot_category || [],
        );
        setCategoryCollection(categoryData);
      } else {
        setCategoryCollection([]);
      }
      setIsCategoryLoading(false);
    });

    spendSupplierFetch.then((reportData) => {
      if (reportData !== 'error') {
        const supplierData = calculateSpendTabColumns(
          reportData?.spend_pivot_supplier || [],
        );
        setSupplierCollection(supplierData);
      } else {
        setSupplierCollection([]);
      }
      setIsSupplierLoading(false);
    });

    return () => {
      spendTeamCancel && spendTeamCancel();
      spendCategoryCancel && spendCategoryCancel();
      spendSupplierCancel && spendSupplierCancel();
    };
  }, [filters]);

  return (
    <Box>
      <SpendGraphs filters={filters} />

      {isEmployeeLoading ? (
        TableLoadingDiv('Spend by employees')
      ) : (
        <ReportTable
          tableHeaders={EMPLOYEE_SPENDING_STATS_HEADERS}
          collection={employeeCollection}
          options={employeeTableOptions}
          filters={filters}
        />
      )}
      {isCategoryLoading ? (
        TableLoadingDiv('Spend by categories')
      ) : (
        <ReportTable
          tableHeaders={CATEGORY_SPENDING_STATS_HEADERS}
          collection={categoryCollection}
          options={categoryTableOptions}
          filters={filters}
        />
      )}
      {isSupplierLoading ? (
        TableLoadingDiv('Spend by suppliers')
      ) : (
        <ReportTable
          tableHeaders={SUPPLIER_SPENDING_STATS_HEADERS}
          collection={supplierCollection}
          options={supplierTableOptions}
          filters={filters}
        />
      )}
    </Box>
  );
};

export default SpendingStats;
