import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import CustomCard from '../../common/CustomCard';
import { formatCurrency } from '../../utils/functions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import StyledIcon from '../../common/StyledIcon';
import { ApartmentRounded, ExpandMore } from '@mui/icons-material';

const formatTitleTotal = (timeframe) => {
  return timeframe === 'max' ? 'all time' : `in last ${timeframe} months`;
};

const formatSupplierNames = (invoices, timeframe, supplierName) => {
  let total = 0;
  const startDate =
    timeframe === 'max'
      ? moment(invoices[0]?.issue_date || moment()).subtract(1, 'day')
      : moment().subtract(timeframe, 'month');

  const currentInvoices = [...invoices]
    .sort((a, b) => new Date(a.issue_date) - new Date(b.issue_date))
    .filter((entry) => {
      if (timeframe === 'max') return true;
      return !moment(entry.issue_date).isBefore(startDate);
    })
    .reduce((acc, entry) => {
      const legal_name = entry.legal_name || supplierName;
      if (!acc[legal_name]) {
        acc[legal_name] = { name: legal_name, value: 0 };
      }
      total += Number(entry.amount_usd_total || 0);
      acc[legal_name].value += Number(entry.amount_usd_total || 0);
      return acc;
    }, {});

  const combinedInvoices = Object.values(currentInvoices);
  return { total, combinedInvoices };
};

const SupplierDetailsStructure = ({
  invoices,
  timeframe,
  sidebar,
  supplierName,
}) => {
  const [spendTotal, setSpendTotal] = useState();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (!invoices) return;

    const { total, combinedInvoices } = formatSupplierNames(
      invoices,
      timeframe,
      supplierName,
    );
    setSpendTotal(total);
    setAccounts(combinedInvoices);
  }, [invoices, timeframe]);

  return (
    <CustomCard height={'100%'}>
      <Box padding={'16px'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingBottom={'16px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) =>
              sidebar ? theme.typography.h6 : theme.typography.h5
            }
          >
            Structure & spend
          </Box>
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.subtitle1}
            display={'flex'}
            alignItems={'center'}
            gap={'4px'}
          >
            {formatCurrency(spendTotal)}
            <Typography
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.secondary}
            >
              {formatTitleTotal(timeframe)}
            </Typography>
          </Box>
        </Box>
        <Accordion
          defaultExpanded={true}
          sx={{
            boxShadow: 'none',
            border: '1.5px solid',
            borderRadius: '8px',
            borderColor: '#6558B1',
            padding: '0 16px',
            '&.Mui-expanded': {
              margin: '0 !important',
              borderColor: '#6558B1',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<StyledIcon component={ExpandMore} color="icon" />}
            sx={{
              display: 'flex',
              padding: 0,
              '&.Mui-expanded': {
                margin: '0 !important',
                minHeight: '0 !important',
                borderBottom: '1px solid #E0E0E0',
              },
              '& .MuiAccordionSummary-content': {
                gap: '4px',
                alignItems: 'center',
              },
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '16px 0',
                alignItems: 'center',
              },
            }}
          >
            <Box
              width={'250px'}
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              gap={'8px'}
            >
              <StyledIcon component={ApartmentRounded} color="icon" />
              <Box
                fontSize={(theme) => theme.typography.subtitle1}
                color={(theme) => theme.palette.text.primary}
              >
                {supplierName}
              </Box>
              <Box
                fontSize={(theme) => theme.typography.caption}
                color={(theme) => theme.palette.text.primary}
                sx={{
                  padding: '2px 4px',
                  borderRadius: '4px',
                  background: '#00000014',
                }}
              >
                Currently viewing
              </Box>
            </Box>
            <Box>{`${accounts?.length || 1} account${
              accounts?.length === 1 ? '' : 's'
            }`}</Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              margin: '0',
              padding: '0',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              padding={'16px 0'}
              gap={'8px'}
            >
              {accounts.map(({ name, value }) => {
                return (
                  <Box
                    display={'flex'}
                    alignContent={'center'}
                    gap={'4px'}
                    key={name}
                  >
                    <Box
                      fontSize={(theme) => theme.typography.subtitle2}
                      color={(theme) => theme.palette.text.primary}
                      width={'250px'}
                    >
                      {name}
                    </Box>
                    <Box
                      fontSize={(theme) => theme.typography.body2}
                      color={(theme) => theme.palette.text.primary}
                    >
                      {formatCurrency(value)}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </CustomCard>
  );
};

export default SupplierDetailsStructure;
