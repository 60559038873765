import {
  ArrowBackIosRounded,
  ArrowForwardRounded,
  CloseRounded,
  MoreVert,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import StyledIcon from './StyledIcon';

import CustomCard from './CustomCard';

const LoadingSkeleton = ({ variant, ...props }) => {
  const navigate = useNavigate();

  switch (variant) {
    case 'adminLogs':
      return (
        <TableRow
          sx={{
            borderBottom: '1.5px solid #694FA31A',
            td: {
              border: 'none',
              padding: '16px',
              color: '#000000DE',
              fontWeight: 'body2',
              fontSize: 'body2',
              whiteSpace: 'nowrap',
            },
            ':last-of-type': { borderBottom: 'none' },
          }}
        >
          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>
        </TableRow>
      );
    case 'templates':
      return (
        <TableRow
          sx={{
            borderBottom: '1.5px solid #694FA31A',
            td: {
              border: 'none',
              padding: '16px',
              color: '#000000DE',
              fontWeight: 'body2',
              fontSize: 'body2',
              whiteSpace: 'nowrap',
            },
            ':last-of-type': { borderBottom: 'none' },
          }}
        >
          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>

          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>
          <TableCell sx={{ paddingRight: '16px' }}>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Skeleton variant={'text'} width={100} />
            </Stack>
          </TableCell>
        </TableRow>
      );
    case 'users':
      return (
        <TableRow
          sx={{
            borderBottom: '1.5px solid #694FA31A',
            td: {
              border: 'none',
              padding: '16px',
              color: '#000000DE',
              fontWeight: 'body2',
              fontSize: 'body2',
              whiteSpace: 'nowrap',
            },
            ':last-of-type': { borderBottom: 'none' },
          }}
        >
          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>

          <TableCell>
            <Skeleton variant={'text'} width={100} />
          </TableCell>
          <TableCell sx={{ paddingRight: '16px' }}>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Skeleton variant={'text'} width={100} />
            </Stack>
          </TableCell>
        </TableRow>
      );

    case 'stats':
      return (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '24px',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ minWidth: '250px', width: 'calc(50% - 12px)' }}>
            <CustomCard>
              <Box
                sx={{
                  padding: '16px',
                  borderBottom: '1px solid #79747E29',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      marginBottom: '8px',
                    }}
                    width={180}
                    height={24}
                    variant={'rounded'}
                  />
                  <Box
                    fontSize={(theme) => theme.typography.h4}
                    color={(theme) => theme.palette.text.main}
                  >
                    <Skeleton variant={'text'} width={100} />
                  </Box>
                </Box>
                <Box width={'50%'}>
                  <Skeleton variant={'rounded'} height={50} width={'100%'} />
                </Box>
              </Box>
              <Box>
                <Stack
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ padding: '16px' }}
                >
                  <Box
                    fontSize={(theme) => theme.typography.body2}
                    color={(theme) => theme.palette.text.medium}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Skeleton variant={'text'} width={100} />
                  </Box>
                  <Skeleton width={110} height={24} variant={'rounded'} />
                </Stack>
              </Box>
            </CustomCard>
          </Box>
          <Box sx={{ minWidth: '250px', width: 'calc(50% - 12px)' }}>
            <CustomCard>
              <Box
                sx={{
                  padding: '16px',
                  borderBottom: '1px solid #79747E29',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box width={'50%'}>
                  <Skeleton
                    sx={{
                      marginBottom: '8px',
                    }}
                    width={180}
                    height={24}
                    variant={'rounded'}
                  />
                  <Box
                    fontSize={(theme) => theme.typography.h4}
                    color={(theme) => theme.palette.text.main}
                  >
                    <Skeleton variant={'text'} width={'100px'} />
                  </Box>
                </Box>
                <Box width={'50%'}>
                  <Skeleton variant={'rounded'} height={50} width={'100%'} />
                </Box>
              </Box>
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ padding: '16px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  <Skeleton variant={'text'} width={'100px'} />
                </Box>
                <Skeleton width={110} height={24} variant={'rounded'} />
              </Stack>
            </CustomCard>
          </Box>
        </Box>
      );

    case 'spendGraphs':
      return (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '24px',
            flexWrap: 'wrap',
            marginBottom: '32px',
          }}
        >
          <Box sx={{ minWidth: '250px', width: 'calc(50% - 12px)' }}>
            <CustomCard>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      marginBottom: '8px',
                    }}
                    width={180}
                    height={24}
                    variant={'rounded'}
                  />
                  <Box
                    fontSize={(theme) => theme.typography.h4}
                    color={(theme) => theme.palette.text.main}
                  >
                    <Skeleton variant={'text'} width={100} />
                  </Box>
                </Box>
                <Box paddingRight={'40px'}>
                  <Skeleton variant={'circular'} width={70} height={70} />
                </Box>
              </Box>
            </CustomCard>
          </Box>
          <Box sx={{ minWidth: '250px', width: 'calc(50% - 12px)' }}>
            <CustomCard>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box width={'50%'}>
                  <Skeleton
                    sx={{
                      marginBottom: '8px',
                    }}
                    width={180}
                    height={24}
                    variant={'rounded'}
                  />
                  <Box
                    fontSize={(theme) => theme.typography.h4}
                    color={(theme) => theme.palette.text.main}
                  >
                    <Skeleton variant={'text'} width={'100px'} />
                  </Box>
                </Box>
                <Box width={'50%'}>
                  <Skeleton variant={'rounded'} height={50} width={'100%'} />
                </Box>
              </Box>
            </CustomCard>
          </Box>
        </Box>
      );
    case 'interactionItem':
      return (
        <CustomCard>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px',
              alignItems: 'center',
              borderBottom: '1px solid #DEDBEC',
            }}
          >
            <Box
              sx={{
                width: 'calc(100% / 3)',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <Skeleton variant={'circular'} width={40} height={40} />
              <Box>
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    lineHeight: '150%',
                  }}
                  fontSize={(theme) => theme.typography.subtitle1}
                >
                  <Skeleton variant={'text'} width={100} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Box
                    color={(theme) => theme.palette.text.medium}
                    fontSize={(theme) => theme.typography.caption}
                  >
                    <Skeleton variant={'text'} width={30} />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: 'calc(100% / 3)',
              }}
            >
              <IconButton type="button" sx={{ padding: '8px' }}>
                <StyledIcon component={ArrowForwardRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ padding: '16px', display: 'flex' }}>
            <Box sx={{ width: 'calc(100% / 3)' }}>
              <Box
                color={(theme) => theme.palette.text.main}
                sx={{
                  marginBottom: '8px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Skeleton variant={'text'} width={50} />
              </Box>
              <Box fontSize={(theme) => theme.typography.body2}>
                <Skeleton variant={'text'} width={50} />
              </Box>
            </Box>
            <Box sx={{ width: 'calc(100% / 3)' }}>
              <Skeleton
                variant={'text'}
                width={50}
                sx={{ marginBottom: '8px' }}
              />

              <Skeleton variant={'text'} width={50} />
            </Box>
            <Skeleton variant={'text'} width={'30%'} />
          </Box>
        </CustomCard>
      );
    case 'interactionDetails':
      return (
        <Box
          sx={{
            minHeight: 'calc(100vh - 88px)',
            maxHeight: 'calc(100vh - 88px)',
            overflowY: 'auto',
            padding: '16px 24px 72px 24px',
            background: 'white',
            '@media (max-width: 768px)': {
              width: 'calc(100vw - 48px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1.5px solid #694FA31A',
              paddingBottom: '20px',
              marginBottom: '24px',
            }}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              Interaction
            </Box>
            <Box>
              <IconButton
                type="button"
                sx={{ padding: '8px' }}
                onClick={props?.close}
              >
                <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '16px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <Skeleton variant={'circular'} width={44} height={44} />
              <Box>
                <Skeleton
                  variant={'text'}
                  width={300}
                  sx={{ fontSize: '20px' }}
                />
                <Skeleton variant={'text'} width={50} />
              </Box>
            </Box>
            <Box>
              <IconButton type="button" sx={{ padding: '8px' }}>
                <MoreVert />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              borderBottom: '1.5px solid #694FA31A',
              paddingBottom: '16px',
              marginBottom: '16px',
            }}
          >
            <Skeleton variant={'text'} />

            <Skeleton variant={'text'} />

            <Skeleton variant={'text'} />

            <Skeleton variant={'text'} />
          </Box>
          <Box sx={{ marginBottom: '24px' }}>
            <Skeleton variant={'text'} />

            <Skeleton variant={'text'} />

            <Skeleton variant={'text'} />
          </Box>
          <Box
            sx={{
              border: '1px solid #694FA31A',
              borderRadius: '12px',
              overflow: 'hidden',
              marginBottom: '24px',
            }}
          >
            <Box
              sx={{
                padding: '16px',
                paddingBottom: '0',
              }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Employees
              </Box>
            </Box>
            <Box>
              {[0].map((_, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      borderTop: index !== 0 && '1.5px solid #694FA31A',
                      borderBottom: '1.5px solid #694FA31A',
                      padding: index === 0 ? '16px' : '24px 16px 16px',
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={20}
                      sx={{ borderRadius: '30px' }}
                    />
                  </Box>
                  <Box sx={{ overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            borderBottom:
                              '1.5px solid rgba(101, 88, 177, 0.07)',
                            th: {
                              border: 'none',
                              padding: '16px',
                              paddingRight: '0',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#000000DE',
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                            >
                              Name
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                            >
                              Title
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Messages
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Last active
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[0].map((_, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              borderBottom: '1.5px solid #694FA31A',
                              td: {
                                border: 'none',
                                padding: '16px',
                                paddingRight: '0',
                                color: '#000000DE',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                              },
                              ':last-of-type': { borderBottom: 'none' },
                            }}
                          >
                            <TableCell>
                              <Skeleton variant={'text'} width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant={'text'} width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant={'text'} width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant={'text'} width={100} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              border: '1.5px solid #694FA31A',
              borderRadius: '12px',
              marginBottom: '24px',
            }}
          >
            <Box
              sx={{
                padding: '16px',
                borderBottom: '1.5px solid #694FA31A',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.h6}
                color={(theme) => theme.palette.text.main}
              >
                Supplier contacts
              </Box>
            </Box>
            <Box>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        borderBottom: '1.5px solid #694FA31A',
                        padding: '16px',
                        paddingRight: '0',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#000000DE',
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Name
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Title
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Messages
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Last active
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[0].map((_, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: '1.5px solid #694FA31A',
                        td: {
                          border: '0px',
                          padding: '16px',
                          paddingRight: '0',
                          color: '#000000DE',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                        },
                        ':last-of-type': { borderBottom: 'none' },
                      }}
                    >
                      <TableCell>
                        <Skeleton variant={'text'} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={100} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box>
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
              sx={{ marginBottom: '16px' }}
            >
              Interaction log
            </Box>
            <Box>
              {[0, 1]?.map((_, index) => (
                <Stack
                  key={index}
                  direction={'row'}
                  gap={'16px'}
                  width={'100%'}
                >
                  <Stack
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'start'}
                  >
                    <Skeleton
                      variant={'circular'}
                      width={24}
                      height={24}
                      sx={{ minHeight: 24 }}
                    />

                    {index < 1 && (
                      <Box
                        sx={{
                          width: '2px',
                          height: '100%',
                          background: '#DEDBEC',
                        }}
                      />
                    )}
                  </Stack>
                  <Box
                    width={'100%'}
                    sx={{
                      borderBottom:
                        index < 1 && '1px solid rgba(188, 184, 197, 0.2)',
                      marginBottom: '16px',
                      paddingBottom: '16px',
                    }}
                  >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Skeleton variant={'text'} width={100} />

                      <Skeleton variant={'text'} width={100} />
                    </Stack>
                    <Skeleton variant={'text'} width={100} />
                  </Box>
                </Stack>
              ))}
            </Box>
          </Box>
        </Box>
      );
    case 'supplierDetails':
      return (
        <Box
          sx={{
            width: '720px',
            minHeight: 'calc(100vh - 32px)',
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
            padding: '16px 24px',
            background: 'white',
            '@media (max-width: 768px)': {
              width: 'calc(100vw - 48px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1.5px solid #694FA31A',
              paddingBottom: '20px',
              marginBottom: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              <IconButton
                type="button"
                sx={{ padding: '0' }}
                onClick={() => navigate(-1)}
              >
                <StyledIcon component={ArrowBackIosRounded} color={'icon'} />
              </IconButton>
              Supplier details
            </Box>
            <Box>
              <IconButton
                type="button"
                sx={{ padding: '0' }}
                onClick={props.close}
              >
                <StyledIcon component={CloseRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Box>
          <Stack
            direction={'row'}
            gap={'16px'}
            alignItems={'center'}
            sx={{ marginBottom: '16px' }}
          >
            <Skeleton variant={'circular'} width={50} height={50} />
            <Skeleton variant={'text'} width={200} sx={{ fontSize: '32px' }} />
          </Stack>
          <Skeleton
            variant={'text'}
            sx={{
              marginBottom: '24px',
              fontSize: 'body1',
            }}
          />
          <Box
            fontSize={(theme) => theme.typography.body2}
            sx={{
              marginBottom: '24px',
              color: '#0F0030',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'body2',
              }}
            />
            <Skeleton
              variant={'text'}
              sx={{
                fontSize: 'caption',
              }}
            />
          </Box>
          <Box
            sx={{
              border: '1.5px solid #694FA31A',
              borderRadius: '12px',
              marginBottom: '24px',
            }}
          >
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1.5px solid #694FA31A',
              }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Contacts
              </Box>
              <Box></Box>
            </Box>
            <Box>
              <Table sx={{ overflow: 'visible' }}>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        borderBottom: '1.5px solid #694FA31A',
                        padding: '16px',
                        paddingRight: '0',
                        fontWeight: 'subtitle2',
                        fontSize: 'subtitle2',
                        color: '#000000DE',
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Name
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Title
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Messages
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Last active
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[1]?.map((contact, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: '1.5px solid #694FA31A',
                        td: {
                          border: 'none',
                          padding: '16px',
                          paddingRight: '0',
                          color: '#000000DE',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                        },
                        ':last-of-type': { borderBottom: 'none' },
                      }}
                    >
                      <TableCell>
                        <Skeleton variant={'text'} width={'100px'} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={'100px'} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={'100px'} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant={'text'} width={'100px'} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.h6}
                color={(theme) => theme.palette.main}
              >
                Interaction history
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              {[0].map((_, index) => (
                <LoadingSkeleton variant={'interactionItem'} key={index} />
              ))}
            </Box>
          </Box>
        </Box>
      );
    case 'supplierDetailsFullscreen':
      return (
        <Box
          sx={{
            minHeight: 'calc(100vh - 64px)',
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            padding: '16px 24px',
            background: 'white',
            '@media (max-width: 768px)': {
              width: 'calc(100vw - 64px)',
            },
          }}
          display={'flex'}
          gap={'16px'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '20px',
              margin: '16px',
              gap: '24px',
              flexBasis: '100%',
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
              <Stack display={'flex'} flexDirection={'row'} gap={'10px'}>
                <Skeleton variant={'circular'} width={40} height={40} />
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    lineHeight: '150%',
                  }}
                  fontSize={(theme) => theme.typography.h4}
                >
                  <Skeleton variant={'text'} width={50} height={40} />
                </Box>
              </Stack>
              <Skeleton variant="rounded" width={210} height={20} />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rounded" width={700} height={200} />
          </Box>
        </Box>
      );
    default:
      return <></>;
  }
};

export default LoadingSkeleton;
