import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoMessageHover from '../../common/InfoMessageHover';

const calculatePercentage = (value, total) => {
  return (value / total) * 100;
};

const getStyle = (stage, percentage) => ({
  display: `${percentage ? 'flex' : 'none'}`,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '110px',
  width: `${percentage}%`,
  height: '32px',
  background:
    stage === 'approval'
      ? '#FFDBD2'
      : stage === 'committed'
      ? '#CCE7FF'
      : '#D5F7CF',
  color:
    stage === 'approval'
      ? '#3C0800'
      : stage === 'committed'
      ? '#004376'
      : '#196C32',
  fontWeight: '400',
  fontSize: '14px',
  opacity: '0.7',
  borderRadius:
    stage === 'approval'
      ? '2px 0 0 2px'
      : stage === 'billed'
      ? '0 2px 2px 0'
      : '0',
  borderRight: stage !== 'billed' ? '1px solid white' : '',
});

const SpendBarGraph = ({ label, spend, legend }) => {
  const [percentages, setPercentages] = useState([]);

  useEffect(() => {
    if(!spend) return;
    setPercentages([
      calculatePercentage(spend.approval, spend.value),
      calculatePercentage(spend.committed, spend.value),
      calculatePercentage(spend.billed, spend.value),
    ]);
  }, [spend]);

  if (!spend || !percentages.length) return;

  return (
    <Stack
      color={(theme) => theme.palette.text.main}
      fontSize={(theme) => theme.typography.body2}
    >
      {legend && (
        <Box
          fontSize={(theme) => theme.typography.body1}
          width={'100%'}
          display={'flex'}
          paddingBottom={'4px'}
        >
          {label && <Box width={'123px'} />}
          <Box display={'flex'} width={'100%'}>
            {percentages.map(
              (percent) =>
                percent > 0 && (
                  <Box
                    key={`percent-${percent}`}
                    textAlign={'center'}
                    width={`${percent}%`}
                    minWidth={'110px'}
                  >
                    {' '}
                    {`${Math.round(percent)}%`}{' '}
                  </Box>
                ),
            )}
          </Box>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'}>
        {label && (
          <Box minWidth={'115px'} maxWidth={'115px'} paddingRight={'8px'} textAlign={'right'}>
            {label}
          </Box>
        )}
        <Box display={'flex'} width={'100%'}>
          <Box sx={getStyle('approval', percentages[0])}>
            {Boolean(percentages[0]) && spend.approvalFormatted}
          </Box>
          <Box sx={getStyle('committed', percentages[1])}>
            {Boolean(percentages[1]) && spend.committedFormatted}
          </Box>
          <Box sx={getStyle('billed', percentages[2])}>
            {Boolean(percentages[2]) && spend.billedFormatted}
          </Box>
        </Box>
      </Box>
      {legend && (
        <Box width={'100%'} display={'flex'} paddingTop={'4px'}>
          {Boolean(percentages[0]) && (
            <Box
              width={`${percentages[0]}%`}
              minWidth={'110px'}
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.caption}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              gap={'4px'}
            >
              <Box>In approval</Box>{' '}
              <InfoMessageHover message={'Zip requests pending approval'} />
            </Box>
          )}
          {Boolean(percentages[1]) && (
            <Box
              width={`${percentages[1]}%`}
              minWidth={'110px'}
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.caption}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              gap={'4px'}
            >
              <Box>Committed</Box>{' '}
              <InfoMessageHover
                message={'Approved purchase orders in Netsuite not yet billed'}
              />
            </Box>
          )}
          {Boolean(percentages[2]) && (
            <Box
              width={`${percentages[2]}%`}
              minWidth={'110px'}
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.caption}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              gap={'4px'}
            >
              <Box>Billed</Box>{' '}
              <InfoMessageHover message={'Approved invoices in Netsuite'} />
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default SpendBarGraph;
