import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomActiveDot from '../../common/CustomActiveDot';
import DateTooltip from '../../common/DateTooltip';
import { formatCurrency } from '../../utils/functions';
import CustomCard from '../../common/CustomCard';
import moment from 'moment';
import { Box, Link, Stack, useTheme } from '@mui/material';
import StyledIcon from '../../common/StyledIcon';
import {
  TrendingDownRounded,
  TrendingFlatRounded,
  TrendingUpRounded,
} from '@mui/icons-material';

const IconDescriptionVariant = (variant) => {
  if (variant > 0) {
    return <StyledIcon component={TrendingUpRounded} color={'icon'} />;
  } else if (variant < 0) {
    return <StyledIcon component={TrendingDownRounded} color={'icon'} />;
  } else {
    return <StyledIcon component={TrendingFlatRounded} color={'icon'} />;
  }
};

const formatYAxis = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

const generateGraphDays = (startDate, endDate) => {
  const start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');

  const days = end.diff(start, 'days') + 1;

  return Array.from({ length: days }, (_, i) => {
    const date = start.clone().add(i, 'days').format('YYYY-MM-DD');
    return {
      date,
      value: 0,
      monthLabel: `${moment(date).format('MMM YY')}`,
    };
  });
};

const SupplierDetailsSpendGraph = ({ data, timeframe, summary }) => {
  const theme = useTheme();
  const [total, setTotal] = useState();
  const [percentChange, setPercentChange] = useState();
  const [graphFormattedData, setGraphFormattedData] = useState([]);

  useEffect(() => {
    const invoices = [...data].sort(
      (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
    );
    let total = 0;
    let previousTotal = 0;
    const currentDate = moment();
    const startDate =
      timeframe === 'max'
        ? moment(invoices[0]?.issue_date || moment()).subtract(1, 'day')
        : moment().subtract(timeframe, 'month');

    const currentInvoices = invoices.filter((entry) => {
      if (timeframe === 'max') return true;
      return !moment(entry.issue_date).isBefore(startDate);
    });

    const summedInvoices = currentInvoices.reduce((acc, entry) => {
      if (!acc[entry.issue_date]) {
        acc[entry.issue_date] = { date: entry.issue_date, value: 0 };
      }
      acc[entry.issue_date].value += Number(entry.amount_usd_total || 0);
      return acc;
    }, {});

    // Convert the object back into an array
    const combinedInvoices = Object.values(summedInvoices);

    const fullGraphData = generateGraphDays(startDate, currentDate).map(
      (entry) => {
        const matchingDate = combinedInvoices.find((invoice) =>
          moment(invoice.date).isSame(entry.date, 'day'),
        );

        if (matchingDate) {
          total += Number(matchingDate.value || 0);
        }
        return {
          ...entry,
          value: total,
          label: `${moment(entry.date).format('DD MMM')} - ${formatCurrency(
            total,
          )}`,
        };
      },
    );

    setTotal(total);
    setGraphFormattedData(fullGraphData);

    if (timeframe === 'max') {
      setPercentChange(0);
    } else {
      invoices
        .filter((entry) => {
          return moment(entry.issue_date).isBetween(
            moment().subtract(11 + timeframe, 'month'),
            moment().subtract(13, 'month'),
          );
        })
        .map((entry) => {
          previousTotal = Number(entry.amount_usd_total || 0) + previousTotal;
        });
      setPercentChange(
        Math.round(((total - previousTotal) / previousTotal) * 100),
      );
    }
  }, [timeframe]);

  const get_color = () => {
    return theme.palette.icon.main;
  };

  if (summary && !total) return <></>;

  const displayedMonths = new Set();

  return (
    <CustomCard height={'100%'}>
      <Box padding={'16px'}>
        <Stack gap={'2px'} paddingBottom={'8px'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) =>
                summary ? theme.typography.h6 : theme.typography.h5
              }
            >
              {formatCurrency(total)}{' '}
              {Boolean(summary) && 'spend in last 12 months'}
            </Box>
            {Boolean(summary) && (
              <Link
                sx={{ cursor: 'pointer' }}
                color="secondary"
                fontSize={(theme) => theme.typography.subtitle2}
                underline="hover"
                onClick={() => {
                  summary();
                }}
              >
                See structure & spend
              </Link>
            )}
          </Box>
          <Box
            fontSize={(theme) => theme.typography.body2}
            color={(theme) => theme.palette.text.medium}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {IconDescriptionVariant(percentChange)}{' '}
            <Box>
              <Box component={'span'} color={get_color(percentChange)}>
                {percentChange !== Infinity
                  ? Math.abs(percentChange).toFixed(1)
                  : 0}
                %
              </Box>{' '}
              vs last year
            </Box>
          </Box>
        </Stack>
        <ResponsiveContainer width="99%" height={250}>
          <LineChart height={250}>
            <XAxis
              dataKey="date"
              tickLine={false}
              tick={({ x, y, payload }) => {
                const monthLabel = graphFormattedData.find(
                  (d) => d.date === payload.value,
                )?.monthLabel;

                if (!monthLabel || displayedMonths.has(monthLabel)) return null;

                displayedMonths.add(monthLabel);
                return (
                  <text
                    x={x}
                    y={y + 10}
                    textAnchor="middle"
                    fill="rgba(0, 0, 0, 0.87)"
                  >
                    {monthLabel}
                  </text>
                );
              }}
            />
            <YAxis tickFormatter={(value) => formatYAxis(value)} />
            <CartesianGrid vertical={false} />
            <Line
              dataKey="value"
              data={graphFormattedData}
              stroke={'#6558B1'}
              type="monotone"
              dot={() => null}
              activeDot={
                <CustomActiveDot
                  r={3}
                  xAxis={{ x: 0 }}
                  yAxis={{ y: 0 }}
                  colorDot={'#6558B1'}
                  height={250}
                />
              }
              isAnimationActive={false}
            />

            {/* {Boolean(percentChange) && (
              <Line
                dataKey="previous"
                data={graphFormattedData}
                stroke={'#9E9E9E'}
                strokeDasharray="5 5"
                type="monotone"
                connectNulls
                dot={() => null}
                activeDot={
                  <CustomActiveDot
                    r={3}
                    xAxis={{ x: 0 }}
                    yAxis={{ y: 0 }}
                    colorDot={'#9E9E9E'}
                    height={250}
                  />
                }
                isAnimationActive={false}
              />
            )} */}

            <Tooltip
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
              allowEscapeViewBox={{ x: true, y: true }}
              content={<DateTooltip valueKey={'label'} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </CustomCard>
  );
};

export default SupplierDetailsSpendGraph;
