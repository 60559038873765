import { Drawer } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InteractionDetails from './interactionDetails/InteractionDetails';
import SupplierDetails from './supplierDetails/SupplierDetails';
import SupplierDetailsFullscreen from './supplierDetails/SupplierDetailsFullscreen';
import { appSlice } from '../store/appSlice';

const Details = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setSearch, setPreviousSearch, toggleDetailsFullscreen, clearDetailsNavigation } = appSlice.actions;
  const { page, search, previousSearch, detailsFullscreen } = useSelector((state) => state.appSlice);

  const [isOpen, setIsOpen] = useState(false);
  const [useSupplierFullscreen, setUseSupplierFullscreen] = useState(detailsFullscreen);

  useEffect(() => {
    document.title = 'PartnerElement | Details';

    if(searchParams.get('fullscreen')) {
      dispatch(toggleDetailsFullscreen());
      searchParams.delete('fullscreen');
    }

    if(searchParams.get('activityType'))
      setUseSupplierFullscreen(true);

    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  }, []);

  useEffect(() => {
    if(searchParams.get('activityType') || detailsFullscreen) {
      setUseSupplierFullscreen(true);
    } else {
      setUseSupplierFullscreen(false);
    }
  }, [searchParams, detailsFullscreen])

  const handleClose = (syntheticClick, newPage) => {
    if (!syntheticClick && !newPage) return
    setIsOpen(false);
    dispatch(clearDetailsNavigation());
    searchParams.delete('activityType');
    if (detailsFullscreen) {
      dispatch(toggleDetailsFullscreen());
    }
    if(syntheticClick?.currentTarget && previousSearch && !search) {
      dispatch(setSearch(previousSearch));
    }
    dispatch(setPreviousSearch(''));
    document.title = `PartnerElement | ${
      page?.charAt(0).toUpperCase() + page?.slice(1)
    }`;
    setTimeout(() => {
      if (page === 'overview') {
        navigate('/');
      } else if (
        !newPage ||
        newPage === 'backdropClick' ||
        newPage === 'escapeKeyDown'
      ) {
        navigate(`/${page}?${searchParams}`);
      }
    }, 300);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'div',
        sx: {
          overflowX: 'hidden',
          width: useSupplierFullscreen ? '100%' : '768px',
          top: '0',
        },
      }}
    >
      <AnimatePresence mode={'sync'}>
        <Routes location={location}>
          <Route
            path="supplier/:id"
            element={
              <motion.div
                key="supplier"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                {useSupplierFullscreen ? (
                  <SupplierDetailsFullscreen close={handleClose} />
                ) : (
                  <SupplierDetails close={handleClose} />
                )}
              </motion.div>
            }
          />
          <Route
            path="interaction/:id"
            element={
              <motion.div
                key="interaction"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                <InteractionDetails close={handleClose} />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
    </Drawer>
  );
};

export default Details;
