import { useEffect, useState } from 'react';
import SpendByTeamModal from '../reports/SpendByTeamModal';
import SpendPieChart from '../reports/SpendPieChart';
import CustomCard from '../../common/CustomCard';
import { Box, Link } from '@mui/material';
import moment from 'moment';

const COLORS = [
  '#FFB4A2',
  '#88DBDF',
  '#88ABDF',
  '#80E0A7',
  '#F3D03E',
  '#FF808B',
  '#FDAA63',
  '#FFE7B3',
];
const PIE_CHART_MAX_ROWS = 4;

const formatCurrency = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

const formatCurrencyExpanded = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const formatSpendStats = (data, reduceType) => {
  const aggregateData = data.reduce((acc, curr) => {
    const existing = acc.find((item) => item[reduceType] === curr[reduceType]);

    if (!existing) {
      const updated = { ...curr };
      updated.label = curr[reduceType];
      updated.value = Number(curr.amount_usd_total);
      acc.push({ ...updated });
      return acc;
    }
    existing.value += Number(curr.amount_usd_total);

    return acc;
  }, []);

  const fullSpend = aggregateData.reduce((acc, item) => acc + item.value, 0);

  let totalOtherValue = 0;
  const colors =
    reduceType === 'spend_account' ? COLORS.slice().reverse() : COLORS;
  const formatted = aggregateData
    .sort((a, b) => b.value - a.value)
    .map((entry, index) => {
      if (index > PIE_CHART_MAX_ROWS - 1) totalOtherValue += entry.value;

      return {
        ...entry,
        color: colors[index % COLORS.length],
        totalFormatted: formatCurrencyExpanded(entry.value),
        percent: `${((entry.value / fullSpend) * 100).toFixed(0)}%`,
      };
    });

  return {
    formatted,
    reducedView:
      formatted.length > PIE_CHART_MAX_ROWS + 1
        ? [
            ...formatted.slice(0, PIE_CHART_MAX_ROWS).map((entry) => {
              return { ...entry, totalFormatted: formatCurrency(entry.value) };
            }),
            {
              label: 'Other',
              value: totalOtherValue,
              totalFormatted: formatCurrency(totalOtherValue),
              percent: `${((totalOtherValue / fullSpend) * 100).toFixed(0)}%`,
              color: colors.at(-2),
            },
          ]
        : formatted,
  };
};

const SupplierDetailsSpendPieChart = ({ invoices, timeframe, sidebar }) => {
  const [isSpendByTeamOpen, setIsSpendByTeamOpen] = useState(false);
  const [isSpendByCategoryOpen, setIsSpendByCategoryOpen] = useState(false);
  const [teamCollection, setTeamCollection] = useState([]);
  const [categoryCollection, setCategoryCollection] = useState([]);

  useEffect(() => {
    const currentInvoices = invoices.filter((entry) => {
      if (timeframe === 'max') return true;
      return !moment(entry.issue_date).isBefore(
        moment().subtract(timeframe, 'month'),
      );
    });

    const costCenterSpend = formatSpendStats(
      currentInvoices,
      'spend_cost_center',
    );
    const accountSpend = formatSpendStats(currentInvoices, 'spend_account');

    setTeamCollection(costCenterSpend);
    setCategoryCollection(accountSpend);
  }, [timeframe]);

  if (!teamCollection?.formatted?.length) return;

  return (
    <>
      <Box width={sidebar ? '632px' : '100%'}>
        <CustomCard height={'100%'}>
          <Box width={'99%'} display={'flex'} padding={'16px'}>
            <Box
              width={'50%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Spend by team
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                {teamCollection?.reducedView.map((spend, index) => (
                  <Box
                    key={`by-team-${spend.label}-${index}`}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'8px'}
                  >
                    <Box>
                      <svg width="8" height="8">
                        <circle cx="4" cy="4" r="4" fill={spend.color} />
                      </svg>
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.black}
                      fontSize={(theme) => theme.typography.body2}
                      width={'120px'}
                    >
                      {spend.label || 'Unidentified'}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {spend.totalFormatted}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {`(${spend.percent})`}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box width={'50%'}>
              <SpendPieChart data={teamCollection?.reducedView} />
            </Box>
          </Box>
          {Boolean(
            teamCollection?.formatted.length > PIE_CHART_MAX_ROWS + 1,
          ) && (
            <Box
              sx={{
                padding: '16px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <Link
                color="secondary"
                sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
                underline="hover"
                onClick={() => {
                  setIsSpendByTeamOpen(true);
                }}
              >
                See all teams
              </Link>
            </Box>
          )}
        </CustomCard>
      </Box>
      <Box width={sidebar ? '632px' : '100%'}>
        <CustomCard height={'100%'}>
          <Box width={'99%'} display={'flex'} padding={'16px'}>
            <Box
              width={'50%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Spend by category
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                {categoryCollection?.reducedView.map((spend, index) => (
                  <Box
                    key={`by-team-${spend.label}-${index}`}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'8px'}
                  >
                    <Box>
                      <svg width="8" height="8">
                        <circle cx="4" cy="4" r="4" fill={spend.color} />
                      </svg>
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.black}
                      fontSize={(theme) => theme.typography.body2}
                      width={'120px'}
                    >
                      {spend.label || 'Unidentified'}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {spend.totalFormatted}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {`(${spend.percent})`}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box width={'50%'}>
              <SpendPieChart data={categoryCollection?.reducedView} />
            </Box>
          </Box>
          {Boolean(
            categoryCollection?.formatted.length > PIE_CHART_MAX_ROWS + 1,
          ) && (
            <Box
              sx={{
                padding: '16px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <Link
                color="secondary"
                sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
                underline="hover"
                onClick={() => {
                  setIsSpendByCategoryOpen(true);
                }}
              >
                See all categories
              </Link>
            </Box>
          )}
        </CustomCard>
      </Box>
      <SpendByTeamModal
        open={isSpendByTeamOpen}
        handleClose={() => {
          setIsSpendByTeamOpen(false);
        }}
        collection={teamCollection?.formatted}
      />
      <SpendByTeamModal
        open={isSpendByCategoryOpen}
        handleClose={() => {
          setIsSpendByCategoryOpen(false);
        }}
        collection={categoryCollection?.formatted}
        label={'category'}
        pluralLabel={'categories'}
      />
    </>
  );
};

export default SupplierDetailsSpendPieChart;
