import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import DetailsNavigation from '../../common/DetailsNavigation';
import { CloseRounded } from '@mui/icons-material';
import ShareLink from '../../common/ShareLink';
import { capitalizeFirst } from '../../utils/functions';
import InteractionItem from '../../common/InteractionItem';
import ActivityItem from '../../common/ActivityItem';
import Header from '../Header';

function formatText(collection, type) {
  return (collection?.length || 0) === 1 ? type : `${type}s`;
}

function sortRecommendedRequests(collection) {
  const pendingRequests = collection
    .filter((item) => item.status === 'pending')
    .sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
  const otherRequests = collection
    .filter((item) => item.status !== 'pending')
    .sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
  return [...pendingRequests, ...otherRequests];
}

function sortByDate(collection, direction, label) {
  return collection.sort((a, b) => {
    if (direction === 'asc') return new Date(a[label]) - new Date(b[label]);
    return new Date(b[label]) - new Date(a[label]);
  });
}

const SupplierActivityFullscreen = ({
  type,
  close,
  supplier,
  interactions,
  requests,
  handleClick,
}) => {
  const [sortedRequests, setSortedRequests] = useState();
  const [sortedInteractions, setSortedInteractions] = useState();
  const [sortOrderInt, setSortOrderInt] = useState('int-rec');
  const [sortOrderReq, setSortOrderReq] = useState('req-rec');

  const handleSortChange = (type, direction) => {
    if (type === 'requests') {
      setSortOrderReq(direction);
    } else {
      setSortOrderInt(direction);
    }
  };

  useEffect(() => {
    if (!requests?.length) return;
    let sorted = [];
    switch (sortOrderReq) {
      case 'req-asc':
        sorted = sortByDate(requests, 'asc', 'started_at');
        break;
      case 'req-desc':
        sorted = sortByDate(requests, 'desc', 'started_at');
        break;
      default:
        sorted = sortRecommendedRequests(requests);
    }

    setSortedRequests(sorted.slice());
  }, [sortOrderReq, requests]);

  useEffect(() => {
    if (!interactions?.length) return;
    let sorted = [];
    switch (sortOrderInt) {
      case 'int-asc':
        sorted = sortByDate(interactions, 'asc', 'start_time');
        break;
      case 'int-desc':
        sorted = sortByDate(interactions, 'desc', 'start_time');
        break;
      default:
        sorted = interactions;
    }

    setSortedInteractions(sorted.slice());
  }, [sortOrderInt, interactions]);

  return (
    <Box>
      <Box>
        <Header handleOnClick={close} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: '16px 24px 0 120px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <DetailsNavigation handleClose={close} />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Box>Suppliers</Box>
                <Box> / {supplier.name}</Box>
                <Box color={(theme) => theme.palette.text.secondary}>
                  {' '}
                  / {capitalizeFirst(type)}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <ShareLink />
              <IconButton type="button" sx={{ padding: '8px' }} onClick={close}>
                <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box paddingLeft={'120px'} paddingBottom={'24px'} width={'66%'}>
          {Boolean(sortedInteractions?.length && type === 'interactions') && (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: '16px',
                  gap: '4px',
                }}
              >
                <Box
                  color={(theme) => theme.palette.text.secondary}
                  fontSize={(theme) => theme.typography.body1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box
                    fontSize={(theme) => theme.typography.body1}
                    color={(theme) => theme.palette.text.caption}
                  >
                    {interactions.length}{' '}
                    {formatText(interactions, 'interaction')} found.
                  </Box>
                  <Box sx={{ width: '160px' }}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel htmlFor="sortedByInt" color={'secondary'}>
                        Sort by
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: '10px' }}
                        color={'secondary'}
                        size={'small'}
                        id={'sortedByInt'}
                        label={'Sort by'}
                        value={sortOrderInt}
                        onChange={(event) =>
                          handleSortChange('interactions', event?.target?.value)
                        }
                      >
                        <MenuItem value={'int-rec'}>Recommended</MenuItem>
                        <MenuItem value={'int-asc'}>Earliest date</MenuItem>
                        <MenuItem value={'int-desc'}>Newest date</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: '16px',
                }}
              >
                {sortedInteractions?.map((item) => (
                  <Box
                    width={'100%'}
                    key={item.id}
                    onClick={() => handleClick()}
                  >
                    <InteractionItem
                      interaction={item}
                      variant={'DetailsFullscreen'}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {Boolean(sortedRequests?.length && type === 'requests') && (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: '16px',
                  gap: '4px',
                }}
              >
                <Box
                  color={(theme) => theme.palette.text.secondary}
                  fontSize={(theme) => theme.typography.body1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box
                    fontSize={(theme) => theme.typography.body1}
                    color={(theme) => theme.palette.text.caption}
                  >
                    {requests.length} {formatText(requests, 'purchase request')}{' '}
                    found.
                  </Box>
                  <Box sx={{ width: '160px' }}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel htmlFor="sortedByReq" color={'secondary'}>
                        Sort by
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: '10px' }}
                        color={'secondary'}
                        size={'small'}
                        id={'sortedByReq'}
                        label={'Sort by'}
                        value={sortOrderReq}
                        onChange={(event) =>
                          handleSortChange('requests', event?.target?.value)
                        }
                      >
                        <MenuItem value={'req-rec'}>Recommended</MenuItem>
                        <MenuItem value={'req-asc'}>Earliest date</MenuItem>
                        <MenuItem value={'req-desc'}>Newest date</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: '16px',
                }}
              >
                {sortedRequests.map((item) => (
                  <Box width={'100%'} key={item.id}>
                    <ActivityItem activity={item} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierActivityFullscreen;
