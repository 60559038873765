import { Box, IconButton, Modal, Stack } from '@mui/material';
import React from 'react';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import { InnerTable } from './ReportTable';
import { capitalizeFirst } from '../../utils/functions';

const ReportTableModal = ({
  open,
  handleClose,
  collectionText,
  collection,
  tableHeaders,
  options,
  filters,
  modalToggle,
  requestModalToggle,
  supplierModalToggle,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '830px',
          maxWidth: '1080px',
          maxHeight: '640px',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'4px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            {`${options.title || capitalizeFirst(options.plural)}`}
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          paddingBottom={'16px'}
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'end'}
          borderBottom={'1.5px solid #694FA31A'}
        >
          <Box color={(theme) => theme.palette.text.medium}>
            {collectionText}
          </Box>
        </Stack>
        <InnerTable
          collection={collection}
          tableHeaders={tableHeaders}
          options={options}
          allowSortBy={tableHeaders[0]?.sort}
          filters={filters}
          modalToggle={modalToggle}
          requestModalToggle={requestModalToggle}
          supplierModalToggle={supplierModalToggle}
        />
      </Box>
    </Modal>
  );
};

export default ReportTableModal;
