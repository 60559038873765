import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import NoResultCard from '../../common/NoResultCard';
import InteractionItem from '../../common/InteractionItem';
import ActivityItem from '../../common/ActivityItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appSlice } from '../../store/appSlice';

function formatText(collection, type) {
  return (collection?.length || 0) === 1 ? type : `${type}s`;
}

function sortRecommendedRequests(collection) {
  const pendingRequests = collection
    .filter((item) => item.status === 'pending')
    .sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
  const otherRequests = collection
    .filter((item) => item.status !== 'pending')
    .sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
  return [...pendingRequests, ...otherRequests];
}

function sortByDate(collection, direction, label) {
  return collection.sort((a, b) => {
    if (direction === 'asc') return new Date(a[label]) - new Date(b[label]);
    return new Date(b[label]) - new Date(a[label]);
  });
}

const SupplierActivities = ({ interactions, requests, handleClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sortedRequests, setSortedRequests] = useState();
  const [sortedInteractions, setSortedInteractions] = useState();
  const [sortOrderInt, setSortOrderInt] = useState('int-rec');
  const [sortOrderReq, setSortOrderReq] = useState('req-rec');

  const {
    detailsNavigation: { previous },
  } = useSelector((state) => state.appSlice);

  const { setPreviousDetailsNavigation, setNextDetailsNavigation } =
    appSlice.actions;

  const updateLocation = (previous, next) => {
    dispatch(setPreviousDetailsNavigation(previous));
    dispatch(setNextDetailsNavigation(next));
  };

  const goForward = () => {
    const newPrevious = [...previous, `${location.pathname}${location.search}`];
    updateLocation(newPrevious, []);
  };

  const handleSortChange = (type, direction) => {
    if (type === 'requests') {
      setSortOrderReq(direction);
    } else {
      setSortOrderInt(direction);
    }
  };

  useEffect(() => {
    if (!requests?.length) return;
    let sorted = [];
    switch (sortOrderReq) {
      case 'req-asc':
        sorted = sortByDate(requests, 'asc', 'started_at');
        break;
      case 'req-desc':
        sorted = sortByDate(requests, 'desc', 'started_at');
        break;
      default:
        sorted = sortRecommendedRequests(requests);
    }

    setSortedRequests(sorted.slice(0, 3));
  }, [sortOrderReq, requests]);

  useEffect(() => {
    if (!interactions?.length) return;
    let sorted = [];
    switch (sortOrderInt) {
      case 'int-asc':
        sorted = sortByDate(interactions, 'asc', 'start_time');
        break;
      case 'int-desc':
        sorted = sortByDate(interactions, 'desc', 'start_time');
        break;
      default:
        sorted = interactions;
    }

    setSortedInteractions(sorted.slice(0, 3));
  }, [sortOrderInt, interactions]);

  if (!interactions?.length && !requests?.length)
    return <NoResultCard variant="supplier-interactions" />;

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      {Boolean(interactions?.length) && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '16px',
              gap: '4px',
            }}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.primary}
            >
              Interactions
            </Box>
            <Box
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.body1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.caption}
              >
                {interactions.length} {formatText(interactions, 'interaction')}{' '}
                found.
              </Box>
              <Box sx={{ width: '160px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel htmlFor="sortedByInt" color={'secondary'}>
                    Sort by
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: '10px' }}
                    color={'secondary'}
                    size={'small'}
                    id={'sortedByInt'}
                    label={'Sort by'}
                    value={sortOrderInt}
                    onChange={(event) =>
                      handleSortChange('interactions', event?.target?.value)
                    }
                  >
                    <MenuItem value={'int-rec'}>Recommended</MenuItem>
                    <MenuItem value={'int-asc'}>Earliest date</MenuItem>
                    <MenuItem value={'int-desc'}>Newest date</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            {sortedInteractions?.map((item) => (
              <Box width={'100%'} key={item.id} onClick={() => handleClick()}>
                <InteractionItem
                  interaction={item}
                  variant={'DetailsFullscreen'}
                />
              </Box>
            ))}
            {interactions?.length > 3 && (
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                fontSize={(theme) => theme.typography.subtitle2}
                color="secondary"
                onClick={() => {
                  goForward();
                  navigate('?activityType=interactions');
                }}
              >
                See all interactions
              </Link>
            )}
          </Box>
        </Box>
      )}
      {Boolean(requests?.length) && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '16px',
              gap: '4px',
            }}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.primary}
            >
              Purchase requests
            </Box>
            <Box
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.body1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.caption}
              >
                {requests.length} {formatText(requests, 'purchase request')}{' '}
                found.
              </Box>
              <Box sx={{ width: '160px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel htmlFor="sortedByReq" color={'secondary'}>
                    Sort by
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: '10px' }}
                    color={'secondary'}
                    size={'small'}
                    id={'sortedByReq'}
                    label={'Sort by'}
                    value={sortOrderReq}
                    onChange={(event) =>
                      handleSortChange('requests', event?.target?.value)
                    }
                  >
                    <MenuItem value={'req-rec'}>Recommended</MenuItem>
                    <MenuItem value={'req-asc'}>Earliest date</MenuItem>
                    <MenuItem value={'req-desc'}>Newest date</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            {sortedRequests?.map((item) => (
              <Box width={'100%'} key={item.id}>
                <ActivityItem activity={item} />
              </Box>
            ))}
            {requests?.length > 3 && (
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                fontSize={(theme) => theme.typography.subtitle2}
                color="secondary"
                onClick={() => {
                  goForward();
                  navigate('?activityType=requests');
                }}
              >
                See all purchase requests
              </Link>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierActivities;
