import React from 'react';
import { Box } from '@mui/material';
import NoResultIcon from './icons/NoResultIcon.svg';
import styled from '@emotion/styled';
import ClearFiltersButton from './ClearFilters';

const StyledNoResultBox = styled(Box)({
  borderRadius: '12px',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});

const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const ImageBox = styled(Box)({
  width: '150px',
  height: '150px',
  marginBottom: '16px',
});

const MessageBox = styled(Box)({
  marginBottom: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const DescriptionBox = styled(Box)({
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const NoResultCard = ({ variant = 'search' }) => {
  const buttonText = variant === 'search' ? 'Clear search' : 'Clear filters';
  const messageText =
    variant === 'filtersSuppliers' ? 'suppliers' : 'interactions';

  switch (variant) {
    case 'contracts':
      return (
        <StyledNoResultBox>
          <ContentBox>
            <ImageBox>
              <img src={NoResultIcon} alt="No result" />
            </ImageBox>

            <MessageBox
              color={(theme) => theme.palette.text.black}
              fontSize={(theme) => theme.typography.h6}
            >
              No contracts found
            </MessageBox>
            <DescriptionBox
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              There is no contract data found related to this supplier.
            </DescriptionBox>
          </ContentBox>
        </StyledNoResultBox>
      );
    case 'SupplierSpend':
      return (
        <StyledNoResultBox>
          <ContentBox>
            <ImageBox>
              <img src={NoResultIcon} alt="No result" />
            </ImageBox>

            <MessageBox
              color={(theme) => theme.palette.text.black}
              fontSize={(theme) => theme.typography.h6}
            >
              No spend found
            </MessageBox>
            <DescriptionBox
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              There is no spend data found related to this supplier.
            </DescriptionBox>
          </ContentBox>
        </StyledNoResultBox>
      );
    case 'supplier-people':
      return (
        <StyledNoResultBox>
          <ContentBox>
            <ImageBox>
              <img src={NoResultIcon} alt="No result" />
            </ImageBox>

            <MessageBox
              color={(theme) => theme.palette.text.black}
              fontSize={(theme) => theme.typography.h6}
            >
              No contacts found
            </MessageBox>
            <DescriptionBox
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              There are no contacts found related to this supplier.
            </DescriptionBox>
          </ContentBox>
        </StyledNoResultBox>
      );
    case 'supplier-interactions':
      return (
        <StyledNoResultBox>
          <ContentBox>
            <ImageBox>
              <img src={NoResultIcon} alt="No result" />
            </ImageBox>

            <MessageBox
              color={(theme) => theme.palette.text.black}
              fontSize={(theme) => theme.typography.h6}
            >
              No interactions found
            </MessageBox>
            <DescriptionBox
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              There are no interactions found related to this supplier.
            </DescriptionBox>
          </ContentBox>
        </StyledNoResultBox>
      );
  }

  return (
    <StyledNoResultBox>
      <ContentBox>
        <ImageBox>
          <img src={NoResultIcon} alt="No result" />
        </ImageBox>

        <MessageBox
          color={(theme) => theme.palette.text.black}
          fontSize={(theme) => theme.typography.h6}
        >
          No {messageText} found
        </MessageBox>
        <DescriptionBox
          color={(theme) => theme.palette.text.caption}
          fontSize={(theme) => theme.typography.body1}
        >
          Clear your filters or search by supplier, team, or employees.
        </DescriptionBox>

        <Box>
          <ClearFiltersButton
            variant={variant}
            customText={buttonText}
          ></ClearFiltersButton>
        </Box>
      </ContentBox>
    </StyledNoResultBox>
  );
};

export default NoResultCard;
