import React, { useState } from 'react';
import ReportTable from './ReportTable';
import CustomCard from '../../common/CustomCard';
import { Box, IconButton, Link, Stack } from '@mui/material';
import { PEOPLE_PROCUREMENT_ENGAGEMENT_HEADERS } from './reportHelpers';
import EngagementTimingLine from './EngagementTimingLine';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import StyledIcon from '../../common/StyledIcon';
import EngagementTimingByPeopleModal from './EngagementTimingByPeopleModal';

export const CollapseItem = ({ label, collection, requestDetail }) => {
  const engagementTimingTableOptions = {
    type: 'employee',
    includeBorder: false,
    includeHeader: false,
    modalPerRow: requestDetail.filter((item) => item.user_name === label),
    plural: 'employees',
  };
  return (
    <Box
      sx={{
        overflowX: 'auto',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        minWidth: '100%',
        marginTop: '8px',
      }}
    >
      <ReportTable
        tableHeaders={PEOPLE_PROCUREMENT_ENGAGEMENT_HEADERS}
        collection={collection.filter((item) => item.user_name === label)}
        options={engagementTimingTableOptions}
      />
    </Box>
  );
};

const EngagementTimingByPeople = ({
  collection,
  peopleDetail,
  requestDetail,
}) => {
  const [collapseItems, setCollapseItems] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={'16px'}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.main}
            >
              Engagement timing by people
            </Box>
          </Stack>
          <Stack flexDirection={'column'} gap={'16px'}>
            {collection?.slice(0, 3).map((item) => (
              <Box width={'100%'} key={item?.user_name}>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  height={'40px'}
                >
                  <Stack width={'100%'} gap={'4px'} flexDirection={'column'}>
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      {`${item.user_name} (${item?.count} request${
                        item?.count === 1 ? '' : 's'
                      }, ${item?.price_dollars_usd})`}
                    </Box>
                    <Stack flexDirection={'row'}>
                      <EngagementTimingLine item={item} />
                    </Stack>
                  </Stack>

                  <Stack
                    key={item?.user_name}
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={'8px'}
                    marginLeft={'16px'}
                  >
                    <IconButton
                      sx={{
                        padding: '8px',
                        transform:
                          collapseItems.includes(item?.user_name) &&
                          'rotate(-180deg)',
                        transition: 'transform 300ms',
                      }}
                      onClick={() =>
                        setCollapseItems((prevState) =>
                          collapseItems.includes(item.user_name)
                            ? prevState.filter((i) => i !== item.user_name)
                            : [...prevState, item.user_name],
                        )
                      }
                    >
                      <StyledIcon
                        component={KeyboardArrowDownRounded}
                        color={'icon'}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                {collapseItems.includes(item?.user_name) && (
                  <CollapseItem
                    label={item.user_name}
                    collection={peopleDetail}
                    requestDetail={requestDetail}
                  />
                )}
              </Box>
            ))}
          </Stack>
        </Box>
        {collection.length > 3 && (
          <Box
            sx={{
              padding: '16px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link
              color="secondary"
              sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
              underline="hover"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              {`See all people`}
            </Link>
          </Box>
        )}
      </CustomCard>
      <EngagementTimingByPeopleModal
        open={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        peopleDetail={peopleDetail}
        requestDetail={requestDetail}
        collection={collection}
      />
    </>
  );
};

export default EngagementTimingByPeople;
