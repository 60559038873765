import React from "react";
import { Box } from "@mui/material";

const CustomCard = ({ children, isHover, onClick, height }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        border: "1px solid #DEDBEC",
        borderRadius: "12px",
        background: "white",
        width: "100%",
        height: height || "auto",
        ":hover": isHover && {
          cursor: "pointer",
          boxShadow:
            "0px 1px 8px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default CustomCard;
