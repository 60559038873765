import {
  AccessTimeFilled,
  LanOutlined,
  TaskRounded,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import CustomCard from './CustomCard';
import StyledIcon from './StyledIcon';
import Logo from './Logo';
import { formatCurrency } from '../utils/functions';
import { ReactComponent as CancelledContract } from './icons/cancelContract.svg';
import { ReactComponent as RequestQuote } from './icons/request_quote.svg';

const ActivityItem = ({ activity }) => {
  const statusIcon = (status) => {
    if (status === 'approved')
      return <StyledIcon component={TaskRounded} color={'icon_success'} />;
    if (status === 'pending')
      return <StyledIcon component={AccessTimeFilled} color={'icon_warning'} />;
    if (status === 'rejected' || status === 'cancelled')
      return <StyledIcon component={CancelledContract} color={'#ACA39A'} />;
    return <></>;
  };

  const statusText = (status) => {
    if (status === 'approved') return <Box>Approved</Box>;
    if (status === 'pending') return <Box>In progress</Box>;
    if (status === 'rejected') return <Box>Cancelled</Box>;
    return <></>;
  };

  return (
    <CustomCard
      isHover={true}
      onClick={() => window.open(activity.request_link, '_blank')}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
          alignItems: 'center',
          borderBottom: '1px solid #DEDBEC',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Logo size={24} img={'/ZipHQ-icon.png'} />
          <Box
            color={(theme) => theme.palette.secondary.main}
            fontSize={(theme) => theme.typography.body1}
          >
            {activity.label}
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: '16px', display: 'flex' }}>
        <Box sx={{ width: '33%' }}>
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <StyledIcon component={RequestQuote} color={'icon'} />
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              <Box color={(theme) => theme.palette.text.main}>
                {formatCurrency(activity.price_usd)}
              </Box>
            </Box>
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={'span'}
              color={(theme) => theme.palette.text.medium}
            >
              Start date:{' '}
            </Box>
            <Box component={'span'} color={(theme) => theme.palette.text.main}>
              {moment(activity.started_at).format('MMM DD, YYYY')}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '33%' }}>
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.body1}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <StyledIcon component={LanOutlined} color={'icon'} />
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              <Box color={(theme) => theme.palette.text.main}>
                {activity.department}
              </Box>
            </Box>
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={'span'}
              color={(theme) => theme.palette.text.medium}
            >
              Initiated by:{' '}
            </Box>
            <Box component={'span'} color={(theme) => theme.palette.text.main}>
              {activity.requester_name}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '33%' }}>
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            {statusIcon(activity.status)} {statusText(activity.status)}
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default ActivityItem;
