import { Box, IconButton, Modal, Stack } from '@mui/material';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import DepartmentTimingBar from './DepartmentTimingBar';

const EngagementTimingByDepartmentModal = ({
  open,
  handleClose,
  collection,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '1080px',
          maxWidth: '1080px',
          maxHeight: '640px',
          overflow: 'auto',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'4px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Purchase timeline breakdown
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          marginBottom={'24px'}
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'end'}
        >
          <Box color={(theme) => theme.palette.text.medium}>
            {`${collection.length} team${
              collection?.length === 1 ? '' : 's'
            } total`}
          </Box>
        </Stack>
        <Stack flexDirection={'column'} gap={'16px'}>
          <Stack
            marginBottom={'24px'}
            marginLeft={'138px'}
            flexDirection={'row'}
            gap={'16px'}
          >
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#FFDBD2'}
              ></Box>
              Days without strategic sourcing
            </Box>
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#CCE7FF'}
              ></Box>
              Days with strategic sourcing
            </Box>
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#D5F7CF'}
              ></Box>
              Days in workflow
            </Box>
          </Stack>
          <Stack flexDirection={'column'} gap={'10px'}>
            {collection.map((item) => {
              return (
                <DepartmentTimingBar
                  key={item.user_department_name}
                  item={item}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EngagementTimingByDepartmentModal;
