import React, { useEffect, useState } from 'react';
import EngagementTimingByPeople from './EngagementTimingByPeople';
import {
  calculatePeopleTimeline,
  calculatePeopleDetailsColumns,
  generateQueryParams,
  calculateDepartmentTimelineColumns,
  calculateRequestDetailsColumns,
} from './reportHelpers';
import { Box, Link, Stack, Table, TableBody } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import DepartmentTimingBar from './DepartmentTimingBar';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import { encodeQuery } from '../../store/storeFunctions';
import { FetchWithCancel } from '../../common/FetchWithCancel';
import EngagementTimingByDepartmentModal from './EngagementTimingByDepartmentModal';

const ProcurementEngagement = ({ filters }) => {
  const [departmentTimeline, setDepartmentTimeline] = useState([]);
  const [peopleTimeline, setPeopleTimeline] = useState([]);
  const [peopleDetail, setPeopleDetail] = useState([]);
  const [requestDetail, setRequestDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getTimeline = (subjectType) => {
    const query = {
      subject: subjectType,
      ...generateQueryParams(filters),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/timeline`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    setIsLoading(true);
    const {
      fetchPromise: departmentTimelineFetch,
      cancel: departmentTimelineCancel,
    } = getTimeline('department');
    const { fetchPromise: userTimelineFetch, cancel: userTimelineCancel } =
      getTimeline('user');

    departmentTimelineFetch.then((departmentData) => {
      if (departmentData !== 'error') {
        const collection = calculateDepartmentTimelineColumns(
          departmentData?.report || [],
        );
        setDepartmentTimeline(collection);
      } else {
        setDepartmentTimeline([]);
      }
    });

    userTimelineFetch.then((userData) => {
      if (userData !== 'error') {
        const requestUserData = userData?.report || [];
        const requestCollection =
          calculateRequestDetailsColumns(requestUserData);
        const peopleCollection = calculatePeopleTimeline(requestUserData);
        const peopleDetailCollection = peopleCollection
          .map((person) => {
            return calculatePeopleDetailsColumns(
              requestUserData.filter(
                (item) => item.user_name === person.user_name,
              ),
            );
          })
          .flat(1);
        setPeopleTimeline(peopleCollection);
        setPeopleDetail(peopleDetailCollection);
        setRequestDetail(requestCollection);
      } else {
        setPeopleTimeline([]);
        setPeopleDetail([]);
        setRequestDetail([]);
      }
      setIsLoading(false);
    });

    return () => {
      departmentTimelineCancel && departmentTimelineCancel();
      userTimelineCancel && userTimelineCancel();
    };
  }, [filters]);

  if (isLoading)
    return (
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    );

  return (
    <Box>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={'16px'}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.main}
            >
              Purchase timeline breakdown
            </Box>
          </Stack>
          <Stack flexDirection={'column'} gap={'10px'}>
            {departmentTimeline
              .sort(
                (a, b) => b.request_number_nunique - a.request_number_nunique,
              )
              .slice(0, 5)
              .map((item) => {
                return (
                  <DepartmentTimingBar
                    key={item.user_department_name}
                    item={item}
                  />
                );
              })}
          </Stack>
          <Stack
            marginTop={'24px'}
            marginLeft={'138px'}
            flexDirection={'row'}
            gap={'16px'}
          >
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#FFDBD2'}
              ></Box>
              Days without strategic sourcing
            </Box>
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#CCE7FF'}
              ></Box>
              Days with strategic sourcing
            </Box>
            <Box
              display={'flex'}
              gap={'4px'}
              color={'#00000099'}
              fontSize={'14px'}
            >
              <Box
                opacity={'0.7'}
                borderRadius={'2px'}
                width={'7px'}
                backgroundColor={'#D5F7CF'}
              ></Box>
              Days in workflow
            </Box>
          </Stack>
        </Box>
        {departmentTimeline.length > 3 && (
          <Box
            sx={{
              padding: '16px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link
              color="secondary"
              sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
              underline="hover"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              {`See all teams`}
            </Link>
          </Box>
        )}
      </CustomCard>
      <Box marginTop={'16px'}>
        <EngagementTimingByPeople
          peopleDetail={peopleDetail}
          requestDetail={requestDetail}
          collection={peopleTimeline}
        />
      </Box>
      <EngagementTimingByDepartmentModal
        open={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        collection={departmentTimeline}
      />
    </Box>
  );
};

export default ProcurementEngagement;
