import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { appSlice } from '../store/appSlice';
import { Box, IconButton } from '@mui/material';
import StyledIcon from './StyledIcon';
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';

const DetailsNavigation = ({ handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    detailsNavigation: { previous, next },
  } = useSelector((state) => state.appSlice);

  const { setPreviousDetailsNavigation, setNextDetailsNavigation } =
    appSlice.actions;

  const updateLocation = (previous, next, url) => {
    dispatch(setPreviousDetailsNavigation(previous));
    dispatch(setNextDetailsNavigation(next));
    navigate(url);
  };

  const goForward = () => {
    if (!next.length) return;
    const url = next[next.length - 1];
    const newPrevious = [...previous, `${location.pathname}${location.search}`];
    updateLocation(newPrevious, next.slice(0, -1), url);
  };

  const goBack = () => {
    if (!previous.length) {
      handleClose({}, 'backdropClick');
      return;
    }
    const url = previous[previous.length - 1];
    const newNext = [...next, `${location.pathname}${location.search}`];
    updateLocation(previous.slice(0, -1), newNext, url);
  };

  return (
    <Box display={'flex'} gap={'8px'}>
      <IconButton type="button" sx={{ padding: '0' }} onClick={() => goBack()}>
        <StyledIcon component={ArrowBackRounded} color={'icon'} />
      </IconButton>
      <IconButton
        disabled={!next.length}
        disableRipple={!next.length}
        type="button"
        sx={{ padding: '0' }}
        onClick={() => goForward()}
      >
        <StyledIcon
          component={ArrowForwardRounded}
          color={next.length ? 'icon' : 'icon_disabled'}
        />
      </IconButton>
    </Box>
  );
};

export default DetailsNavigation;
